import React from "react";
import { Routes, Route } from 'react-router-dom';
import Inicio from '../../Components/Inicio/Inicio'
import Agenda from '../../Components/Agenda/Agenda'
import Rprofesionales from '../../Components/Registrar Profesionales/Registrar_profesionales'
import Profesionales from '../../Components/Profesionales/Profesionales'
import '../../Components/Registrar Profesionales/Registrar_profesionales.css'

function Content() {
    return (
        <div>
            <section id="content-tio">
                <Routes>
                    <Route path='/Inicio' element={<Inicio />} />
                    <Route path='/Agenda' element={<Agenda />} />
                    <Route path='/Profesionales' element={<Profesionales />} />
                    <Route path='/Registrar_profesionales' element={<Rprofesionales />} />
                </Routes>
            </section>
        </div>
    )
}

export default Content;
