import React from "react";




function Rprofesionales() {
    return (
        <div>
            <h5 className="encabezado">| REGISTRAR PROFESIONALES |</h5>
            <div className="Datos">

                <div className="txt_field">
                    <select name="T_documento" id="T_documento">
                        <option value="">Seleccione El Tipo De Documento</option>
                    </select>
                </div>

                <div className="txt_field">
                    <input type="text" id="Documento" name="Documento" placeholder="Documento" required />

                </div>

                <div className="txt_field">
                    <input type="text" id="P_apellido" name="P_apellido" placeholder="Primer Apellido" required />

                </div>

                <div className="txt_field">
                    <input type="text" id="S_apellido" name="S_apellido" placeholder="Segundo Apellido" required />
                </div>

                <div className="txt_field">
                    <input type="text" id="P_nombre" name="P_nombre" placeholder="Primer Nombre" required />

                </div>

                <div className="txt_field">
                    <input type="text" id="S_nombre" name="S_nombre" placeholder="Segundo Nombre" required />
                </div>

                
                <div className="txt_field">
                        <input type="text" id="Fecha_nacimiento" name="Fecha_nacimiento" placeholder="Fecha de nacimiento" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <select name="Especialidad" id="Especialidad">
                            <option value="" selected>Seleccionar Especialidad</option>
                        </select>
                            

                    </div>


                    <div className="txt_field">
                        <input type="text" id="Registro" name="Registro" placeholder="Registro Medico" required />
                            
                    </div>

                    <div className="txt_field">

                        <select name="Sexo" id="Sexo">
                            <option value="0" disabled selected>Sexo</option>
                        </select>
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Correo" name="Correo" placeholder="Correo Electrónico" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Pais" name="Pais" placeholder="Pais" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Departamento" placeholder="Departamento" name="Departamento" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Municipio" name="Municipio" placeholder="Municipio" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Barrio" name="Barrio" placeholder="Barrio" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Zona_residencial" name="Zona_residencial" placeholder="Zona Residencial" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Direccion" name="Direccion" placeholder="Dirección" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Unidad_medida" name="Unidad_medida" placeholder="Unidad de medida edad" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Edad" name="Edad" placeholder="Edad" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Telefono" name="Telefono" placeholder="Teléfono" required="required" />
                            
                    </div>

                    <div className="txt_field">
                        <input type="text" id="Celular" name="Celular" placeholder="Celular" required="required" />
                            
                    </div>


                    <div className="txt_field">
                        <input type="text" id="Cod_prestador" name="Cod_prestador" placeholder="Código Prestador Del Servicio" required="required" />
                            
                    </div>



                    <div className="txt_field">
                        <input type="text" id="Contrasena" name="Contrasena" placeholder="Contraseña" required="required" />
                            
                    </div>


            </div>

            <center>
                <button type="button" className="btn_registrar" name="btn_enviar" id="btnRegistrar">Registrar</button>
                <button type="button" className="btn_cancelar" name="btn_cancelar" id="btnLimpiar">limpiar</button>
            </center>
        </div>
    )
}

export default Rprofesionales