import React from "react";

function Profesionales() {
    return (
        <div>
            <h1>ESTA ES LA PÁGINA DE PROFESIONALES</h1>
        </div>
    )
}

export default Profesionales