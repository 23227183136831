import React from "react";
import Logo_Ceere2 from '../../Assets/Image/Logo_Ceere2.png'

function Inicio() {
    return (
        <div>
            <form action="DataBase/Login_administrador.php" method="POST" className="Bienvenida">
                <h1 className="TituloBienvenida">BIENVENID@ AL MÓDULO ADMINISTRATIVO</h1>
                <img src={Logo_Ceere2} alt="" className="ImagenBienvenida" />
            </form>
        </div>
    )
}

export default Inicio