import React, { useState } from "react";

function Agenda() {
    const [primerNombre, setPrimerNombre] = useState("");
    const [segundoNombre, setSegundoNombre] = useState("");

    const handleRegistrarClick = () => {
        // Realizar la solicitud AJAX usando fetch o axios en lugar de jQuery
        fetch('/DataBase/Consulta.php', {
            method: "POST",
        })
            .then((response) => response.json())
            .then((data) => {
                setPrimerNombre(data.Primer_Nombre);
                setSegundoNombre(data.Segundo_Nombre);
            })
            .catch((error) => {
                alert("Error al consultar la base de datos.");
            });
    };

    const handleLimpiarClick = () => {
        setPrimerNombre("");
        setSegundoNombre("");
    };

    return (
        <div>
            <div className="txt_field">
                <input
                    type="text"
                    id="P_nombre"
                    name="P_nombre"
                    placeholder="Primer Nombre"
                    required
                    value={primerNombre}
                />
            </div>
            <div className="txt_field">
                <input
                    type="text"
                    id="S_nombre"
                    name="S_nombre"
                    placeholder="Segundo Nombre"
                    required
                    value={segundoNombre}
                />
            </div>
            <button
                type="button"
                className="btn_registrar"
                name="btn_enviar"
                onClick={handleRegistrarClick}
            >
                Registrar
            </button>
            <button
                type="button"
                className="btn_cancelar"
                name="btn_cancelar"
                onClick={handleLimpiarClick}
            >
                Limpiar
            </button>
        </div>
    );
}

export default Agenda;
