import React from 'react';
import {FaHouse, FaUsers, FaUserDoctor, FaHandHoldingMedical} from 'react-icons/fa6';
import {GrSchedule, GrPowerShutdown} from 'react-icons/gr';
import {BsFillBuildingFill} from 'react-icons/bs';
import {ImUserPlus} from 'react-icons/im';
import {MdDateRange} from 'react-icons/md';
import {BiMoney} from 'react-icons/bi';
import { Link } from "react-router-dom";





function Sidebar() {
    return (
        <div className="container-fluid">
            <section id="sidebar">
                <ul className="side-menu top">
                    <h1 style={{ textAlign: 'center', color: 'var(--dark)', fontSize: '20px' }}>¡Hola! Administrador Sanarte</h1>
                    <div className="mode-dark">
                        <div className="icono-burguer">
                            <i className='bx bx-menu menu-hamburguesa'></i>
                        </div>
                        <div className="organizar">
                            <input type="checkbox" id="switch-mode" hidden />
                                <label for="switch-mode" className="switch-mode"></label>
                        </div>
                    </div>
                    <li className="active">
                        <Link to='Inicio' href="">
                            <FaHouse className='icon_react' />
                            <span className="text">Inicio</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="Agenda" href="">
                            <GrSchedule className='icon_react'/>
                            <span className="text">Agenda</span>
                        </Link>
                    </li>
                    <li>
                        <a href="#" id="Empresa">
                            <BsFillBuildingFill className='icon_react'/>
                            <span className="text">Empresa</span>
                        </a>
                    </li>
                    <div className="dropdown inactive">
                        <div className="dropdown-company inactive">
                            <a href="Templates/Empresa.php">
                                <span className="text">Crear empresa</span>
                            </a>
                        </div>
                        <div className="dropdown-company inactive">
                            <a href="#">
                                <i className='bx bxs-building'></i>
                                <span className="text">Información de la empresa</span>
                            </a>
                        </div>
                    </div>
                    <li>
                        <Link to="Profesionales" href="" id="modulo">
                            <FaUserDoctor className='icon_react'/>
                            <span className="text">Profesionales</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='Registrar_profesionales' href="">
                        <ImUserPlus className='icon_react'/>
                            <span className="text">Registrar Profesionales</span>
                        </Link>
                    </li>
                    <li>
                        <a href="Templates/Pacientes.php" id="a-reportes">
                            <FaUsers className='icon_react'/>
                            <span className="text">Pacientes</span>
                        </a>
                    </li>
                    <li>
                        <a href="Templates/CitasGenerales.php" id="a-reportes">
                            <MdDateRange className='icon_react' />
                            <span className="text">Citas</span>
                        </a>
                    </li>
                    <li>
                        <a href="Templates/MaestroTarifas.php" id="a-reportes">
                            <BiMoney className='icon_react'/>
                            <span className="text">Maestro Tarifas</span>
                        </a>
                    </li>
                    <li>
                        <a href="Templates/MaestroAseguradoras.php" id="a-reportes">
                            <FaHandHoldingMedical className='icon_react'/>
                            <span className="text">Maestro Aseguradoras</span>
                        </a>
                    </li>
                </ul>
                <ul className="side-menu">

                    <li>
                        <a href="../DataBase/Cerrar_sesion.php" className="logout">
                            <GrPowerShutdown className='icon_react' />
                            <span className="text">Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </section>

        </div>
    );
}

export default Sidebar;
