import React from 'react';
import './Views/Sidebar/Sidebar.css';
import Sidebar from './Views/Sidebar/Sidebar'
import Content from './Views/Content/Content'
import './Views/Content/Content.css'
import { BrowserRouter } from 'react-router-dom'; // Corregir aquí


function App() {
  return (
    <BrowserRouter>
      <div>

        <Sidebar />
        <Content />
      </div>
    </BrowserRouter>
  );
}

export default App;